import {useCallback, useReducer} from "react";
import {client} from "../../api/client";
import useToken from "../useToken";

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_CUSTOMER':
            return { ...state, loading: true }
        case 'RESET_ERRORS':
            return { ...state, loading: false, apiErrors: null }
        case 'SET_CUSTOMERS':
            return { ...state, customers: action.payload, loading: false, apiErrors: null }
        case 'ADD_CUSTOMER':
            return { ...state, customers: [...state.customers, ...action.payload], apiErrors: null }
        case 'ADD_ERRORS':
            return { ...state, apiErrors: action.payload, loading: false }
        case 'UPDATE_CUSTOMER':
            return { ...state, apiErrors: null, loading: false, customers: state.customers.map(customer => {
                    if (customer.id === parseInt(action.payload.id)) {
                        return action.payload
                    }

                    return customer
                })}
        case 'DELETE_CUSTOMER':
            return { ...state, apiErrors: null, loading: false, customers: state.customers.filter(customer => customer.id !== parseInt(action.payload))}
        default:
            throw new Error('Action inconnue ' + action.type)
    }
}

export function useCustomers() {
    const {token} = useToken()
    const [state, dispatch] = useReducer(reducer, {
        loading: true,
        customers: null,
        apiErrors: null
    })

    const fetchAll = useCallback(async () => {
        client('customers', {token})
            .then(result => {
                dispatch({type: 'SET_CUSTOMERS', payload: result.customers})
            })
    })

    const update = useCallback(async (id, inputs) => {
        const formData = new FormData();
        for ( var key in inputs ) {
            if (key !== 'ribFile' && key !== 'contacts') {
                formData.append(key, inputs[key]);
            }
        }

        formData.append('contacts', JSON.stringify(inputs.contacts));

        if (inputs.ribFile) {
            formData.append('ribFile', inputs.ribFile[0])
        }

        dispatch({ type: 'FETCH_CUSTOMER' })
        client(`customer/${id}`, {formData: formData, method: 'POST', token})
            .then(result => {
                dispatch({ type: 'UPDATE_CUSTOMER', payload: result.customer })
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const remove = useCallback(async (id) => {
        dispatch({ type: 'FETCH_CUSTOMER' })
        client(`customer/${id}`, {method: 'DELETE', token})
            .then(result => {
                dispatch({ type: 'DELETE_CUSTOMER', payload: id })
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const add = useCallback(async (inputs) => {
        const formData = new FormData();
        for ( var key in inputs ) {
            if (key !== 'ribFile' && key !== 'contacts') {
                formData.append(key, inputs[key]);
            }
        }

        formData.append('contacts', JSON.stringify(inputs.contacts));

        if (inputs.ribFile) {
            formData.append('ribFile', inputs.ribFile[0])
        }

        dispatch({ type: 'FETCH_CUSTOMER' })
        client(`customer`, {formData: formData, method: 'POST', token})
            .then(result => {
                fetchAll()
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
        dispatch({ type: 'ADD_CUSTOMER', payload: [inputs] })
    })

    const resetApiErrors = () => {
        dispatch({ type: 'RESET_ERRORS' })
    }

    const load = useCallback(async() => {
        dispatch({ type: 'FETCH_CUSTOMER' })
    })

    return [state, { fetchAll, add, update, remove, resetApiErrors, load }]
}