import React, {useEffect, useState} from 'react';
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useHealthInsurance} from "../../context/HealthInsuranceContext";
import {Button, Modal} from "react-bootstrap";

const DeleteHealthInsurance = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [healthInsurance, setHealthInsurance] = useState(null)
    const [canClose, setCanClose] = useState(false)
    const {setSuccessMessage} = useOutletContext()
    let { id } = useParams();

    const {healthInsurances, remove, loading, load, apiErrors, resetApiErrors} = useHealthInsurance()

    useEffect(() => {
        if (healthInsurances) {
            setSuccessMessage(null)
            setHealthInsurance(healthInsurances.find(healthInsurance => healthInsurance.id === parseInt(id)))
        }
    }, [healthInsurances]);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('health-insurance.delete.success_message'))
            handleClose()
        }

    }, [canClose, loading, apiErrors]);

    const handleDelete = () => {
        load()
        remove(id)
        setCanClose(true)
    }

    const handleClose = () => {
        resetApiErrors()
        navigate('/health-insurance')
    }

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('health-insurance.delete.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {healthInsurance &&
                    <>
                        {t('health-insurance.delete.message_validate_deletion')}
                        <div className="mt-3"><strong>{healthInsurance.name}</strong></div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('health-insurance.delete.button.cancel')}
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    {t('health-insurance.delete.button.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteHealthInsurance