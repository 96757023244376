import React, {useEffect, useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useUsers} from "../context/UsersContext";

const DeleteUser = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [user, setUser] = useState(null)
    const [canClose, setCanClose] = useState(false)
    const {setSuccessMessage} = useOutletContext()
    let { id } = useParams();

    const {users, deleteUser, loading, load, apiErrors, resetApiErrors} = useUsers()

    useEffect(() => {
        if (users) {
            setSuccessMessage(null)
            setUser(users.find(user => user.id === parseInt(id)))
        }
    }, [users]);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('users.delete_user.success_message'))
            handleClose()
        }

    }, [canClose, loading, apiErrors]);

    const handleDelete = () => {
        load()
        deleteUser(id)
        setCanClose(true)
    }

    const handleClose = () => {
        resetApiErrors()
        navigate('/user')
    }

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('users.delete_user.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {user &&
                    <>
                        {t('users.delete_user.message_validate_deletion')}
                        <div className="mt-3"><strong>{user.firstName} {user.lastName}</strong> &nbsp;-&nbsp; {t(`users.role.${user.role}`)}</div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('users.delete_user.button.cancel')}
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    {t('users.delete_user.button.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteUser