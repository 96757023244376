import React from 'react';
import DefaultLayout from './components/Layout/DefaultLayout';
import {Route, Routes, useLocation} from "react-router-dom";
import Login from "./pages/Login";
import PageTitle from "./components/PageTitle";
import PasswordLost from "./pages/PasswordLost";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthProvider from "./context/AuthContext";
import Users from "./pages/Users";
import {useTranslation} from "react-i18next";
import UpdateUser from "./pages/UpdateUser";
import UsersProvider from "./context/UsersContext";
import AddUser from "./pages/AddUser";
import DeleteUser from "./pages/DeleteUser";
import ValidateInvitationUser from "./pages/ValidateInvitationUser";
import ListHealthInsurance from "./pages/HealthInsurance/ListHealthInsurance";
import CreateHealthInsurance from "./pages/HealthInsurance/CreateHealthInsurance";
import HealthInsuranceProvider from "./context/HealthInsuranceContext";
import DeleteHealthInsurance from "./pages/HealthInsurance/DeleteHealthInsurance";
import UpdateHealthInsurance from "./pages/HealthInsurance/UpdateHealthInsurance";
import ListCustomer from "./pages/Customer/ListCustomer";
import CreateCustomer from "./pages/Customer/CreateCustomers";
import CustomerProvider from "./context/CustomerContext";
import DeleteCustomer from "./pages/Customer/DeleteCustomer";
import UpdateCustomer from "./pages/Customer/UpdateCustomer";
import ListTiersPayant from "./pages/TiersPayant/ListTiersPayant";
import TiersPayantProvider from "./context/TiersPayantContext";
import CreateTiersPayant from "./pages/TiersPayant/CreateTiersPayant";
import ImportTiersPayant from "./pages/TiersPayant/ImportTiersPayant";
import ShowTiersPayant from "./pages/TiersPayant/ShowTiersPayant";

function App() {
    const { t } = useTranslation();

  return (
      <AuthProvider>
          <Routes>
              <Route
                  path="/"
                  index
                  element={
                      <ProtectedRoute>
                          <DefaultLayout>
                              <h1 className="text-3xl font-bold underline">
                              </h1>
                          </DefaultLayout>
                      </ProtectedRoute>
                  }
              />
              <Route
                  path="login"
                  element={
                      <>
                          <PageTitle title="Se connecter" />
                          <Login />
                      </>
                  }

              />
              <Route
                  path="password-lost"
                  element={
                      <>
                          <PageTitle title="Retrouver son mot de passe" />
                          <PasswordLost />
                      </>
                  }
              />
              <Route
                  path="validate-invitation/:token"
                  element={
                      <>
                          <PageTitle title="Retrouver son mot de passe" />
                          <ValidateInvitationUser />
                      </>
                  }
              />
              <Route
                  path="profile"
                  element={
                      <ProtectedRoute>
                          <PageTitle title={t('users.list.title')} />
                          <DefaultLayout></DefaultLayout>
                      </ProtectedRoute>
                  }
              />
              <Route
                  path="user"
                  element={
                      <ProtectedRoute >
                          <UsersProvider>
                              <Users />
                          </UsersProvider>
                      </ProtectedRoute>
                  }
              >
                  <Route
                      path="new"
                      element={
                          <>
                              <PageTitle title={t('users.list.title')} />
                              <AddUser />
                          </>
                      }
                  />
                  <Route
                      path="update/:id"
                      element={
                        <>
                              <PageTitle title={t('users.list.title')} />
                              <UpdateUser />
                        </>
                      }
                  />
                  <Route
                      path="delete/:id"
                      element={
                          <>
                              <PageTitle title={t('users.list.title')} />
                              <DeleteUser />
                          </>
                      }
                  />
              </Route>
              <Route
                  path="health-insurance"
                  element={
                      <ProtectedRoute >
                          <HealthInsuranceProvider>
                              <ListHealthInsurance />
                          </HealthInsuranceProvider>
                      </ProtectedRoute>
                  }
              >
                  <Route
                      path="new"
                      element={
                          <>
                              <PageTitle title={t('health-insurance.new.head-title')} />
                              <CreateHealthInsurance />
                          </>
                      }
                  />
                  <Route
                      path="update/:id"
                      element={
                          <>
                              <PageTitle title={t('health-insurance.update.head_title')} />
                              <UpdateHealthInsurance />
                          </>
                      }
                  />
                  <Route
                      path="delete/:id"
                      element={
                          <>
                              <PageTitle title={t('health-insurance.delete.head_title')} />
                              <DeleteHealthInsurance />
                          </>
                      }
                  />
              </Route>
              <Route
                  path="customer"
                  element={
                      <ProtectedRoute >
                          <CustomerProvider>
                              <ListCustomer />
                          </CustomerProvider>
                      </ProtectedRoute>
                  }
              >
                  <Route
                      path="new"
                      element={
                          <>
                              <PageTitle title={t('customer.new.head-title')} />
                              <CreateCustomer />
                          </>
                      }
                  />
                  <Route
                      path="update/:id"
                      element={
                          <>
                              <PageTitle title={t('customer.update.head_title')} />
                              <UpdateCustomer />
                          </>
                      }
                  />
                  <Route
                      path="delete/:id"
                      element={
                          <>
                              <PageTitle title={t('customer.delete.head_title')} />
                              <DeleteCustomer />
                          </>
                      }
                  />
              </Route>
              <Route
                  path="tiers-payant"
                  element={
                      <ProtectedRoute >
                          <TiersPayantProvider>
                              <ListTiersPayant />
                          </TiersPayantProvider>
                      </ProtectedRoute>
                  }
              >
                  <Route
                      path="new"
                      element={
                          <>
                              <PageTitle title={t('tiers-payant.new.head-title')} />
                              <CreateTiersPayant />
                          </>
                      }
                  />
                  <Route
                      path="import"
                      element={
                          <>
                              <PageTitle title={t('tiers-payant.import.head-title')} />
                              <ImportTiersPayant />
                          </>
                      }
                  />
                  <Route
                      path="manage/:id"
                      element={
                          <>
                              <PageTitle title={t('tiers-payant.import.head-title')} />
                              <ShowTiersPayant tabsName="manage" />
                          </>
                      }
                  />
                  <Route
                      path="document/:id"
                      element={
                          <>
                              <PageTitle title={t('tiers-payant.document.head-title')} />
                              <ShowTiersPayant tabsName="document" />
                          </>
                      }
                  />
                  <Route
                      path="comment/:id"
                      element={
                          <>
                              <PageTitle title={t('tiers-payant.comment.head-title')} />
                              <ShowTiersPayant tabsName="comment" />
                          </>
                      }
                  />
                  <Route
                      path="update/:id"
                      element={
                          <>
                              <PageTitle title={t('tiers-payant.update.head-title')} />
                              <ShowTiersPayant tabsName="update" />
                          </>
                      }
                  />
                  <Route
                      path="restart_refund/:id"
                      element={
                          <>
                              <PageTitle title={t('tiers-payant.restart_refund.head-title')} />
                              <ShowTiersPayant tabsName="restart_refund" />
                          </>
                      }
                  />
              </Route>
          </Routes>
      </AuthProvider>
  )
}

export default App;
