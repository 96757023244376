import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Modal, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const Step5Cancellation = ({tiersPayant, loading, handleClose, onSubmit, apiErrors}) => {
    const { t } = useTranslation();
    const [isCancelOnClientSoftware, setIsCancelOnClientSoftware] = useState(null)
    const [isReInvoicing, setIsReInvoicing] = useState(null)
    const [hasImpactOnPractitioner, setHasImpactOnPractitioner] = useState(null)
    const [howToManage, setHowToManage] = useState(null)
    const [emails, setEmails] = useState([])
    const [defaultEmails, setDefaultEmails] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
        setError,
        clearErrors
    } = useForm({})

    useEffect(() => {
        if (apiErrors !== null && Object.keys(apiErrors).length > 0) {
            Object.keys(apiErrors).map((key) => {
                setError(key, apiErrors[key])
            })
        }
    }, [apiErrors]);

    useEffect(() => {
        if (tiersPayant) {
            if (tiersPayant.isCancelOnClientSoftware !== null) {
                setValue('isCancelOnClientSoftware', (tiersPayant.isCancelOnClientSoftware === true ? 'true' : 'false'))
                setIsCancelOnClientSoftware(tiersPayant.isCancelOnClientSoftware)
            }
            if (tiersPayant.isReInvoicing !== null) {
                setValue('isReInvoicing', (tiersPayant.isReInvoicing === true ? 'true' : 'false'))
                setIsReInvoicing(tiersPayant.isReInvoicing === true)
            }
            if (tiersPayant.howToManage !== null) {
                setValue('howToManage', tiersPayant.howToManage)
                setHowToManage(tiersPayant.howToManage)
            }
            if (tiersPayant.mailInformation !== null) {
                setValue('mailInformation', tiersPayant.mailInformation)
            }
            if (tiersPayant.hasImpactOnPractitioner !== null) {
                setValue('hasImpactOnPractitioner', (tiersPayant.hasImpactOnPractitioner === true ? 'true' : 'false'))
                setHasImpactOnPractitioner(tiersPayant.hasImpactOnPractitioner)
            }
            if (tiersPayant.newInvoiceNumber !== null) {
                setValue('newInvoiceNumber', tiersPayant.newInvoiceNumber)
            }

            const contactWithEmails = tiersPayant.customer.contacts.filter(contact => {
                return contact.email != null && contact.email !== ''
            })

            const options = contactWithEmails.map(contact => {
                const c = [];
                if (contact.role != null) {
                    c.push(contact.role)
                }
                if (contact.name != null) {
                    c.push(contact.name)
                }
                if (contact.email != null) {
                    c.push(contact.email)
                }

                return { label: c.join(' - '), value: contact.id }
            })
            setEmails(options)

            if (tiersPayant.sendTo !== null) {
                const sendToArray = tiersPayant.sendTo.split(';')
                const defaultOptions = options.filter(element => sendToArray.some(v => element.label.includes(v)))

                setDefaultEmails(defaultOptions)
                setValue('sendTo', defaultOptions)
            }
        }
    }, [tiersPayant]);

    const validateSubmit = ({sendTo, isCancelOnClientSoftware, isReInvoicing, hasImpactOnPractitioner, howToManage, mailInformation, newInvoiceNumber}) => {
        const isCancelOnClientSoftwareBool = isCancelOnClientSoftware === undefined ? null : (isCancelOnClientSoftware === 'true');
        const isReInvoicingBool = isReInvoicing === undefined ? null : (isReInvoicing === 'true');
        const hasImpactOnPractitionerBool = hasImpactOnPractitioner === undefined ? null : (hasImpactOnPractitioner === 'true');
        const sendToIds = sendTo === undefined ? null : Array.from(sendTo, (contact => contact.value))

        onSubmit({
            sendTo: sendToIds,
            isCancelOnClientSoftware: isCancelOnClientSoftwareBool,
            isReInvoicing: isReInvoicingBool,
            hasImpactOnPractitioner: hasImpactOnPractitionerBool,
            howToManage,
            mailInformation,
            newInvoiceNumber
        })
    }

    return (
        <Form onSubmit={handleSubmit(validateSubmit)}>
            <Form.Group className="mb-3" controlId="tiers-payant.is-cancel-on-client-software">
                <Form.Label>{t('tiers-payant.manage.form.is-cancel-on-client-software.label')}</Form.Label>
                <Controller
                    name="isCancelOnClientSoftware"
                    control={control}
                    render={({ field, value, name, ref }) => (
                        <ToggleButtonGroup
                            type="radio"
                            value={field.value}
                            onChange={(val) => {
                                field.onChange(val)
                                setIsCancelOnClientSoftware(val === 'true')
                            }}
                            name="isCancelOnClientSoftware"
                            ref={field.ref}
                            style={{marginLeft: '10px'}}
                        >
                            <ToggleButton id="isCancelOnClientSoftware-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                            <ToggleButton id="isCancelOnClientSoftware-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                        </ToggleButtonGroup>
                    )}
                    size="lg"
                    {...register("isCancelOnClientSoftware", {
                        required: 'error-empty',
                    })}
                />
            </Form.Group>

            {isCancelOnClientSoftware === false && <>
                <Form.Group className="mb-3" controlId="tiers-payant.how-to-manage">
                    <Form.Label>{t('tiers-payant.manage.form.how-to-manage.label')}</Form.Label>
                    <Controller
                        name="howToManage"
                        control={control}
                        render={({ field, value, name, ref }) => (
                            <ToggleButtonGroup
                                type="radio"
                                value={field.value}
                                onChange={(val) => {
                                    field.onChange(val)
                                    setHowToManage(val)
                                }}
                                name="howToManage"
                                ref={field.ref}
                                style={{marginLeft: '10px'}}
                            >
                                <ToggleButton id="howToManage-btn-1" value='internal' variant='outline-info'>En gestion interne</ToggleButton>
                                <ToggleButton id="howToManage-btn-2" value='mail' variant='outline-info'>Demande de document au client par mail</ToggleButton>
                            </ToggleButtonGroup>
                        )}
                        size="lg"
                        {...register("howToManage", {
                            required: 'error-empty',
                        })}
                    />
                </Form.Group>
                {howToManage === 'mail' && <>
                    <Form.Group className="mb-3" controlId="tiers-payant.mail-information">
                        <Form.Label>{t('tiers-payant.manage.form.mail-information.label')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            type="textarea"
                            size="lg"
                            {...register("mailInformation")}
                            isInvalid={formErrors && formErrors.mailInformation}
                        />
                        {formErrors && formErrors.mailInformation && (
                            <Form.Control.Feedback type="invalid">
                                {t(`tiers-payant.manage.form.mail-information.errors.${formErrors.mailInformation.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="tiers-payant.send-to">
                        <Form.Label>{t('tiers-payant.manage.form.send-to.label')}</Form.Label>
                        <Controller
                            name="sendTo"
                            control={control}
                            size="lg"
                            {...register("sendTo", {
                                //required: 'error-empty',
                            })}
                            render={({ field, value, name, ref }) => (
                                <Select
                                    fieldName={field.name}
                                    defaultValue={defaultEmails}
                                    placeholder={t('tiers-payant.manage.form.send-to.placeholder')}
                                    inputRef={field.ref}
                                    options={emails}
                                    value={emails.find((c) => c.value === field.value)}
                                    onChange={val => field.onChange(val)}
                                    isInvalid={formErrors && formErrors.sendTo}
                                    className={`p-0 form-control form-control-lg ${formErrors && formErrors.sendTo ? ' is-invalid' : ''}`}
                                    isMulti
                                />)
                            }
                        />
                        {formErrors && formErrors.sendTo && (
                            <Form.Control.Feedback type="invalid">
                                {t(`tiers-payant.manage.form.send-to.errors.${formErrors.sendTo.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </>}
            </>}

            {isCancelOnClientSoftware === true && <>
                <Form.Group className="mb-3" controlId="tiers-payant.is-re-invoicing">
                    <Form.Label>{t('tiers-payant.manage.form.is-re-invoicing.label')}</Form.Label>
                    <Controller
                        name="isReInvoicing"
                        control={control}
                        render={({ field, value, name, ref }) => (
                            <ToggleButtonGroup
                                type="radio"
                                value={field.value}
                                onChange={(val) => {
                                    field.onChange(val)
                                    setIsReInvoicing(val === 'true')
                                }}
                                name="isReInvoicing"
                                ref={field.ref}
                                style={{marginLeft: '10px'}}
                            >
                                <ToggleButton id="isReInvoicing-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                <ToggleButton id="isReInvoicing-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                            </ToggleButtonGroup>
                        )}
                        size="lg"
                        {...register("isReInvoicing", {
                            required: 'error-empty',
                        })}
                    />
                </Form.Group>
                {isReInvoicing === true && <>
                    <Form.Group className="mb-3 col-md-6" controlId="tiers-payant.new-invoice-number">
                        <Form.Label>{t('tiers-payant.manage.form.new-invoice-number.label')}</Form.Label>
                        <Form.Control
                            type="newInvoiceNumber"
                            placeholder={t('tiers-payant.manage.form.new-invoice-number.place-holder')}
                            size="lg"
                            {...register("newInvoiceNumber")}
                            isInvalid={formErrors && formErrors.newInvoiceNumber}
                        />
                        {formErrors && formErrors.newInvoiceNumber && (
                            <Form.Control.Feedback type="invalid">
                                {t(`tiers-payant.manage.form.new-invoice-number.errors.${formErrors.newInvoiceNumber.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="tiers-payant.has-impact-on-practitioner">
                        <Form.Label>{t('tiers-payant.manage.form.impact-on-practitioner.label')}</Form.Label>
                        <Controller
                            name="hasImpactOnPractitioner"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    type="radio"
                                    value={field.value}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setHasImpactOnPractitioner(val === 'true')
                                        if (val === 'false') {
                                            clearErrors('mailInformation')
                                        }
                                    }}
                                    name="hasImpactOnPractitioner"
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                >
                                    <ToggleButton id="hasImpactOnPractitioner-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="hasImpactOnPractitioner-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("hasImpactOnPractitioner")}
                        />
                    </Form.Group>
                </>}
                {(hasImpactOnPractitioner === true || isReInvoicing === false) && <>
                    <Form.Group className="mb-3" controlId="tiers-payant.mail-information">
                        <Form.Label>{t('tiers-payant.manage.form.mail-information.label')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            type="textarea"
                            size="lg"
                            {...register("mailInformation")}
                            isInvalid={formErrors && formErrors.mailInformation}
                        />
                        {formErrors && formErrors.mailInformation && (
                            <Form.Control.Feedback type="invalid">
                                {t(`tiers-payant.manage.form.mail-information.errors.${formErrors.mailInformation.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="tiers-payant.send-to">
                        <Form.Label>{t('tiers-payant.manage.form.send-to.label')}</Form.Label>
                        <Controller
                            name="sendTo"
                            control={control}
                            size="lg"
                            {...register("sendTo", {
                                required: 'error-empty',
                            })}
                            render={({ field, value, name, ref }) => (
                                <Select
                                    fieldName={field.name}
                                    defaultValue={defaultEmails}
                                    placeholder={t('tiers-payant.manage.form.send-to.placeholder')}
                                    inputRef={field.ref}
                                    options={emails}
                                    value={emails.find((c) => c.value === field.value)}
                                    onChange={val => field.onChange(val)}
                                    isInvalid={formErrors && formErrors.sendTo}
                                    className={`p-0 form-control form-control-lg ${formErrors && formErrors.sendTo ? ' is-invalid' : ''}`}
                                    isMulti
                                />)
                            }
                        />
                        {formErrors && formErrors.sendTo && (
                            <Form.Control.Feedback type="invalid">
                                {t(`tiers-payant.manage.form.send-to.errors.${formErrors.sendTo.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </>}
            </>}

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('tiers-payant.manage.button.next')}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

export default Step5Cancellation