import {useCallback, useReducer} from "react";
import {client} from "../../api/client";
import useToken from "../useToken";

function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_HEALTH_INSURANCE':
            return { ...state, loading: true }
        case 'RESET_ERRORS':
            return { ...state, loading: false, apiErrors: null }
        case 'SET_HEALTH_INSURANCES':
            return { ...state, healthInsurances: action.payload, loading: false, apiErrors: null }
        case 'ADD_HEALTH_INSURANCE':
            return { ...state, healthInsurances: [...state.healthInsurances, ...action.payload], apiErrors: null }
        case 'ADD_ERRORS':
            return { ...state, apiErrors: action.payload, loading: false }
        case 'UPDATE_HEALTH_INSURANCE':
            return { ...state, apiErrors: null, loading: false, healthInsurances: state.healthInsurances.map(healthInsurance => {
                    if (healthInsurance.id === parseInt(action.payload.id)) {
                        return action.payload
                    }

                    return healthInsurance
                })}
        case 'DELETE_HEALTH_INSURANCE':
            return { ...state, apiErrors: null, loading: false, healthInsurances: state.healthInsurances.filter(healthInsurance => healthInsurance.id !== parseInt(action.payload))}
        default:
            throw new Error('Action inconnue ' + action.type)
    }
}

export function useHealthInsurances() {
    const {token} = useToken()
    const [state, dispatch] = useReducer(reducer, {
        loading: true,
        healthInsurances: null,
        apiErrors: null
    })

    const fetchAll = useCallback(async () => {
        dispatch({ type: 'FETCH_HEALTH_INSURANCE' })
        client('health-insurances', {token})
            .then(result => {
                dispatch({type: 'SET_HEALTH_INSURANCES', payload: result.healthInsurances})
            })
    })

    const update = useCallback(async (id, inputs) => {
        dispatch({ type: 'FETCH_HEALTH_INSURANCE' })
        client(`health-insurance/${id}`, {body: inputs, method: 'PUT', token})
            .then(result => {
                dispatch({ type: 'UPDATE_HEALTH_INSURANCE', payload: result.healthInsurance })
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const remove = useCallback(async (id) => {
        dispatch({ type: 'FETCH_HEALTH_INSURANCE' })
        client(`health-insurance/${id}`, {method: 'DELETE', token})
            .then(result => {
                dispatch({ type: 'DELETE_HEALTH_INSURANCE', payload: id })
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
    })

    const add = useCallback(async (inputs) => {
        dispatch({ type: 'FETCH_HEALTH_INSURANCE' })
        client(`health-insurance`, {body: inputs, token})
            .then(result => {
                fetchAll()
            })
            .catch(result => {
                dispatch({ type: 'ADD_ERRORS', payload: result.errors })
            })
        dispatch({ type: 'ADD_HEALTH_INSURANCE', payload: [inputs] })
    })

    const resetApiErrors = () => {
        dispatch({ type: 'RESET_ERRORS' })
    }

    const load = useCallback(async() => {
        dispatch({ type: 'FETCH_HEALTH_INSURANCE' })
    })

    return [state, { fetchAll, add, update, remove, resetApiErrors, load }]
}