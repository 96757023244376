import {Button, Form, Modal, ToggleButton, ButtonGroup, ToggleButtonGroup, Alert} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import SelectHealthInsurance from "../../../components/SelectHealthInsurance";
import {useHealthInsurances} from "../../../hooks/api/healthInsurances";

const Step2Verification = ({tiersPayant, loading, handleClose, onSubmit}) => {
    const { t } = useTranslation();
    const [insuranceIsCorrect, setInsuranceIsCorrect] = useState(0)
    const [updateInsurance, setUpdateInsurance] = useState(0)
    const [newHealthInsurance, setNewHealthInsurance] = useState(null)

    const [sendingType, setSendingType] = useState(null)
    const [amountIsEqual, setAmountIsEqual] = useState(null)
    const [invoiceNumberEqual, setInvoiceNumberEqual] = useState(null)
    const [medicalCareOnlineInvoice, setMedicalCareOnlineInvoice] = useState(null)
    const [medicalCareSigned, setMedicalCareSigned] = useState(null)
    const [dateNotExpired, setDateNotExpired] = useState(null)
    const [{healthInsurances}, {fetchAll: fetchAllHealthInsurance}] = useHealthInsurances()

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setValue,
    } = useForm({})

    useEffect(() => {
        if (tiersPayant) {
            if (tiersPayant.insuranceIsCorrect !== null) {
                setInsuranceIsCorrect((tiersPayant.insuranceIsCorrect === true ? 'true' : 'false'))
                setValue('insuranceIsCorrect', (tiersPayant.insuranceIsCorrect === true ? 'true' : 'false'))
                if (tiersPayant.type == 'prosthesis_orthodontics') {
                    setUpdateInsurance((tiersPayant.insuranceIsCorrect === true ? 'false' : 'true'))
                }
            }

            if (tiersPayant.updateInsurance !== null) {
                setUpdateInsurance((tiersPayant.updateInsurance === true ? 'true' : 'false'))
                setValue('updateInsurance', (tiersPayant.updateInsurance === true ? 'true' : 'false'))
                if (tiersPayant.healthInsurance) {
                    setValue('healthInsuranceId', tiersPayant.healthInsurance.id)
                    setNewHealthInsurance(tiersPayant.healthInsurance.id)
                }
            }

            if (tiersPayant.invoiceNumberEqual !== null) {
                setValue('invoiceNumberEqual', (tiersPayant.invoiceNumberEqual === true ? 'true' : 'false'))
                setInvoiceNumberEqual(tiersPayant.invoiceNumberEqual)
            }
            if (tiersPayant.amountIsEqual !== null) {
                setValue('amountIsEqual', (tiersPayant.amountIsEqual === true ? 'true' : 'false'))
                setAmountIsEqual(tiersPayant.amountIsEqual)
            }
            if (tiersPayant.medicalCareOnlineInvoice !== null) {
                setValue('medicalCareOnlineInvoice', (tiersPayant.medicalCareOnlineInvoice === true ? 'true' : 'false'))
                setMedicalCareOnlineInvoice(tiersPayant.medicalCareOnlineInvoice)
            }
            if (tiersPayant.medicalCareSigned !== null) {
                setValue('medicalCareSigned', (tiersPayant.medicalCareSigned === true ? 'true' : 'false'))
                setMedicalCareSigned(tiersPayant.medicalCareSigned)
            }
            if (tiersPayant.dateNotExpired !== null) {
                setValue('dateNotExpired', (tiersPayant.dateNotExpired === true ? 'true' : 'false'))
                setDateNotExpired(tiersPayant.dateNotExpired)
            }
            if (tiersPayant.hasCpamReimbursement !== null) {
                setValue('hasCpamReimbursement', (tiersPayant.hasCpamReimbursement === true ? 'true' : 'false'))
            }

            if (tiersPayant.calculatedSendingType) {
                setValue('sendingType', tiersPayant.calculatedSendingType)
                setSendingType(tiersPayant.calculatedSendingType)
            }
        }
    }, [tiersPayant]);

    useEffect(() => {
        if (newHealthInsurance && healthInsurances) {
            Object.keys(healthInsurances).map((key) => {
                if (healthInsurances[key].id === newHealthInsurance) {
                    if (tiersPayant.type === 'care') {
                        setValue('sendingType', healthInsurances[key].sendingTypeForCare)
                        setSendingType(healthInsurances[key].sendingTypeForCare)
                    } else {
                        setValue('sendingType', healthInsurances[key].sendingTypeForProsthesisOrthodontics)
                        setSendingType(healthInsurances[key].sendingTypeForProsthesisOrthodontics)
                    }
                }
            })
        }
    }, [newHealthInsurance]);

    const validateSubmit = ({insuranceIsCorrect, sendingType, healthInsuranceId, updateInsurance, amountIsEqual, invoiceNumberEqual, medicalCareOnlineInvoice, medicalCareSigned, dateNotExpired, hasCpamReimbursement}) => {
        const insuranceIsCorrectBool = insuranceIsCorrect === null ? null : (insuranceIsCorrect === 'true');
        const updateInsuranceBool = updateInsurance === null ? null : (updateInsurance === 'true');

        if (tiersPayant.type === 'care') {
            const data = {
                insuranceIsCorrect: insuranceIsCorrectBool,
                sendingType,
                healthInsuranceId,
                updateInsurance: updateInsuranceBool
            }

            if (insuranceIsCorrect === 'true') {
                onSubmit(data)
            } else {
                if (updateInsurance === 'false') {
                    onSubmit(data)
                } else if (healthInsuranceId !== null) {
                    onSubmit(data)
                }
            }
        }

        const amountIsEqualBool = amountIsEqual === null ? null : (amountIsEqual === 'true');
        const invoiceNumberEqualBool = invoiceNumberEqual === null ? null : (invoiceNumberEqual === 'true');
        const medicalCareOnlineInvoiceBool = medicalCareOnlineInvoice === null ? null : (medicalCareOnlineInvoice === 'true');
        const medicalCareSignedBool = medicalCareSigned === null ? null : (medicalCareSigned === 'true');
        const dateNotExpiredBool = dateNotExpired === null ? null : (dateNotExpired === 'true');
        const hasCpamReimbursementBool = hasCpamReimbursement === null ? null : (hasCpamReimbursement === 'true');

        if (tiersPayant.type === 'prosthesis_orthodontics') {
            onSubmit({
                sendingType,
                amountIsEqual: amountIsEqualBool,
                invoiceNumberEqual: invoiceNumberEqualBool,
                medicalCareOnlineInvoice: medicalCareOnlineInvoiceBool,
                medicalCareSigned: medicalCareSignedBool,
                dateNotExpired: dateNotExpiredBool,
                hasCpamReimbursement: hasCpamReimbursementBool,
                insuranceIsCorrect: insuranceIsCorrectBool,
                healthInsuranceId,
                updateInsurance: updateInsuranceBool
            })
        }
    }

    return (
        <Form onSubmit={handleSubmit(validateSubmit)}>
            {tiersPayant.type === 'care' && (
                <>
                    <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                        <Form.Label>{t('tiers-payant.manage.form.insurance-correct.label')}</Form.Label>
                        <Controller
                            name="insuranceIsCorrect"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    type="radio"
                                    value={field.value}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setInsuranceIsCorrect(val)
                                        setNewHealthInsurance(null)
                                        setValue('healthInsuranceId', null)
                                        setValue('updateInsurance', null)
                                        setUpdateInsurance(null)
                                    }}
                                    name="insuranceIsCorrect"
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                >
                                    <ToggleButton id="insuranceIsCorrect-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="insuranceIsCorrect-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("insuranceIsCorrect", {
                                required: 'error-empty',
                            })}
                        />
                    </Form.Group>

                    {insuranceIsCorrect === 'false' && (<Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                        <Form.Label>
                            {t('tiers-payant.manage.form.insurance-not-correct.label')}
                        </Form.Label>
                        <Controller
                            id="updateInsurance"
                            name="updateInsurance"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    id="updateInsurance"
                                    type="radio"
                                    value={field.value}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setUpdateInsurance(val)
                                        if (val === 'false') {
                                            setNewHealthInsurance(null)
                                            setValue('healthInsuranceId', null)
                                        }
                                    }}
                                    name="updateInsurance"
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                >
                                    <ToggleButton id="updateInsurance-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="updateInsurance-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("updateInsurance")}
                        />


                        {updateInsurance === 'true' && (<SelectHealthInsurance
                            name="healthInsuranceId"
                            control={control}
                            placeholder={t('tiers-payant.form.healthInsurance.place-holder')}
                            isInvalid={formErrors && formErrors.healthInsuranceId}
                            healthInsurances={healthInsurances}
                            fetchAllHealthInsurance={fetchAllHealthInsurance}
                            className={`p-0 form-control form-control-lg ${formErrors && formErrors.healthInsuranceId ? ' is-invalid' : ''}`}
                            size="lg"
                            {...register("healthInsuranceId", {
                                onChange: (e) => {
                                    setNewHealthInsurance(e.target.value)
                                }
                            })}
                        />)}
                    </Form.Group>)}

                    {(insuranceIsCorrect === 'false' && updateInsurance === 'false') &&
                        <Alert variant="warning">
                            <div className="alert-message">
                                <span className="block sm:inline">
                                    Cette action entraine la clôture du dossier dans l'application.
                                </span>
                                <div className="block sm:inline">
                                    <strong>Attention, vous devez annulé et re facturé le dossier dans le logiciel du client.</strong>
                                </div>
                            </div>
                        </Alert>}

                    {(insuranceIsCorrect === 'true' || (newHealthInsurance && updateInsurance === 'true')) && (<>
                        <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                            <Form.Label>{t('tiers-payant.manage.form.sending-type.label')}</Form.Label>
                            <Controller
                                name="sendingType"
                                control={control}
                                render={({ field, value, name, ref }) => (
                                    <>
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="teletransmission"
                                            ref={field.ref}
                                            value="teletransmission"
                                            label={t('tiers-payant.manage.form.sending-type.teletransmission')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'teletransmission'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="web_portal"
                                            ref={field.ref}
                                            value="web_portal"
                                            label={t('tiers-payant.manage.form.sending-type.web_portal')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'web_portal'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="letter"
                                            ref={field.ref}
                                            value="letter"
                                            label={t('tiers-payant.manage.form.sending-type.letter')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'letter'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="mail"
                                            ref={field.ref}
                                            value="mail"
                                            label={t('tiers-payant.manage.form.sending-type.mail')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'mail'}
                                        />
                                    </>
                                )}
                                size="lg"
                                {...register("sendingType", {
                                    required: 'error-empty',
                                    onChange: (val) => {
                                        setSendingType(val.target.value)
                                    }
                                })}
                            />
                        </Form.Group>
                    </>)}
                </>
            )}
            {tiersPayant.type === 'prosthesis_orthodontics' && (
                <>
                    <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                        <Form.Label>{t('tiers-payant.manage.form.date-not-expired.label')}</Form.Label>
                        <Controller
                            name="dateNotExpired"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    type="radio"
                                    value={field.value}
                                    name={field.name}
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setDateNotExpired(val === 'true')
                                    }}
                                >
                                    <ToggleButton id="dateNotExpired-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="dateNotExpired-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("dateNotExpired", {
                                required: 'error-empty',
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                        <Form.Label>{t('tiers-payant.manage.form.amount-is-equal.label')}</Form.Label>
                        <Controller
                            name="amountIsEqual"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    type="radio"
                                    value={field.value}
                                    name={field.name}
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setAmountIsEqual(val === 'true')
                                    }}
                                >
                                    <ToggleButton id="amountIsEqual-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="amountIsEqual-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("amountIsEqual", {
                                required: 'error-empty',
                            })}
                        />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                        <Form.Label>{t('tiers-payant.manage.form.medical-care-signed.label')}</Form.Label>
                        <Controller
                            name="medicalCareSigned"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    type="radio"
                                    value={field.value}
                                    name={field.name}
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setMedicalCareSigned(val === 'true')
                                    }}
                                >
                                    <ToggleButton id="medicalCareSigned-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="medicalCareSigned-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("medicalCareSigned", {
                                required: 'error-empty',
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                        <Form.Label>{t('tiers-payant.manage.form.invoice-number-equal.label')}</Form.Label>
                        <Controller
                            name="invoiceNumberEqual"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    type="radio"
                                    value={field.value}
                                    name={field.name}
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setInvoiceNumberEqual(val === 'true')
                                    }}
                                >
                                    <ToggleButton id="invoiceNumberEqual-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="invoiceNumberEqual-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("invoiceNumberEqual", {
                                required: 'error-empty',
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                        <Form.Label>{t('tiers-payant.manage.form.medical-care-online-invoice.label')}</Form.Label>
                        <Controller
                            name="medicalCareOnlineInvoice"
                            control={control}
                            render={({ field, value, name, ref }) => (
                                <ToggleButtonGroup
                                    type="radio"
                                    value={field.value}
                                    name={field.name}
                                    ref={field.ref}
                                    style={{marginLeft: '10px'}}
                                    onChange={(val) => {
                                        field.onChange(val)
                                        setMedicalCareOnlineInvoice(val === 'true')
                                    }}
                                >
                                    <ToggleButton id="medicalCareOnlineInvoice-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                    <ToggleButton id="medicalCareOnlineInvoice-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            size="lg"
                            {...register("medicalCareOnlineInvoice", {
                                required: 'error-empty',
                            })}
                        />
                    </Form.Group>

                    {(amountIsEqual && invoiceNumberEqual && medicalCareOnlineInvoice && medicalCareSigned && dateNotExpired) &&
                        <>
                            <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                                <Form.Label>{t('tiers-payant.manage.form.insurance-correct.label')}</Form.Label>
                                <Controller
                                    name="insuranceIsCorrect"
                                    control={control}
                                    render={({ field, value, name, ref }) => (
                                        <ToggleButtonGroup
                                            type="radio"
                                            value={field.value}
                                            onChange={(val) => {
                                                field.onChange(val)
                                                setInsuranceIsCorrect(val)
                                                setNewHealthInsurance(null)
                                                setValue('healthInsuranceId', null)
                                                setValue('updateInsurance', null)
                                                //TODO setUpdateInsurance(null)

                                                setUpdateInsurance(val === 'false' ? 'true' : 'false')
                                                if (val === 'false') {
                                                    setNewHealthInsurance(null)
                                                    setValue('healthInsuranceId', null)
                                                }
                                            }}
                                            name="insuranceIsCorrect"
                                            ref={field.ref}
                                            style={{marginLeft: '10px'}}
                                        >
                                            <ToggleButton id="insuranceIsCorrect-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                            <ToggleButton id="insuranceIsCorrect-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                        </ToggleButtonGroup>
                                    )}
                                    size="lg"
                                    {...register("insuranceIsCorrect", {
                                        required: 'error-empty',
                                    })}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                                {updateInsurance === 'true' && (<SelectHealthInsurance
                                    name="healthInsuranceId"
                                    control={control}
                                    placeholder={t('tiers-payant.form.healthInsurance.place-holder')}
                                    isInvalid={formErrors && formErrors.healthInsuranceId}
                                    healthInsurances={healthInsurances}
                                    fetchAllHealthInsurance={fetchAllHealthInsurance}
                                    className={`p-0 form-control form-control-lg ${formErrors && formErrors.healthInsuranceId ? ' is-invalid' : ''}`}
                                    size="lg"
                                    {...register("healthInsuranceId", {
                                        onChange: (e) => {
                                            setNewHealthInsurance(e.target.value)
                                        }
                                    })}
                                />)}
                            </Form.Group>
                        <Form.Group className="mb-3" controlId="tiers-payant.insurance-correct">
                            <Form.Label>{t('tiers-payant.manage.form.sending-type.label')}</Form.Label>
                            <Controller
                                name="sendingType"
                                control={control}
                                render={({ field, value, name, ref }) => (
                                    <>
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="mail"
                                            ref={field.ref}
                                            value="mail"
                                            label={t('tiers-payant.manage.form.sending-type.mail')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'mail'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="web_mail"
                                            ref={field.ref}
                                            value="web_mail"
                                            label={t('tiers-payant.manage.form.sending-type.web_mail')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'web_mail'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="web_portal"
                                            ref={field.ref}
                                            value="web_portal"
                                            label={t('tiers-payant.manage.form.sending-type.web_portal')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'web_portal'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="letter"
                                            ref={field.ref}
                                            value="letter"
                                            label={t('tiers-payant.manage.form.sending-type.letter')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'letter'}
                                        />
                                        <Form.Check
                                            name="sendingType"
                                            type="radio"
                                            id="teletransmission"
                                            ref={field.ref}
                                            value="teletransmission"
                                            label={t('tiers-payant.manage.form.sending-type.teletransmission')}
                                            onChange={(val) => field.onChange(val)}
                                            checked={field.value === 'teletransmission'}
                                        />
                                    </>
                                )}
                                size="lg"
                                {...register("sendingType", {
                                    required: 'error-empty',
                                    onChange: (val) => {
                                        setSendingType(val.target.value)
                                    }
                                })}
                            />
                        </Form.Group>
                        {(sendingType === 'mail' || sendingType === 'web_mail') &&
                            <Form.Group className="mb-3" controlId="tiers-payant.has-cpam-reimbursement">
                                <Form.Label>{t('tiers-payant.manage.form.has-cpam-reimbursement.label')}</Form.Label>
                                <Controller
                                    name="hasCpamReimbursement"
                                    control={control}
                                    render={({ field, value, name, ref }) => (
                                        <ToggleButtonGroup
                                            type="radio"
                                            value={field.value}
                                            name={field.name}
                                            ref={field.ref}
                                            style={{marginLeft: '10px'}}
                                            onChange={(val) => {
                                                field.onChange(val)
                                            }}
                                        >
                                            <ToggleButton id="hasCpamReimbursement-btn-1" value='true' variant='outline-info'>Oui</ToggleButton>
                                            <ToggleButton id="hasCpamReimbursement-btn-2" value='false' variant='outline-info'>Non</ToggleButton>
                                        </ToggleButtonGroup>
                                    )}
                                    size="lg"
                                    {...register("hasCpamReimbursement", {
                                        required: 'error-empty',
                                    })}
                                />
                            </Form.Group>
                        }
                    </>}
                </>
            )}

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('tiers-payant.manage.button.next')}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

export default Step2Verification