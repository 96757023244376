import {Link, Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import {useCustomer} from "../../context/CustomerContext";
import SuccessAlert from "../../components/Layout/SuccessAlert";
import {useAuth} from "../../context/AuthContext";

const ListCustomer = () => {
    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState(null)
    const {customers, fetchAll} = useCustomer()
    const {hasRight} = useAuth()

    useEffect(() => {
        fetchAll()
    }, []);

    return (
        <DefaultLayout>
            {hasRight('customer_new') && <Link
                to={'/customer/new'}
                className="btn btn-primary float-end mt-n1"
            >
                <i className="fas fa-plus" style={{marginRight: '10px'}}></i>
                {t('customer.list.button.add')}
            </Link>}
            <h1 className="h3 mb-3">{t('customer.list.title')}</h1>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        {successMessage && <SuccessAlert message={successMessage} /> }
                        <div className="table-responsive">
                            <table className="table mb-0  table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">{t('customer.list.groupName')}</th>
                                        <th scope="col">{t('customer.list.name')}</th>
                                        <th scope="col">{t('customer.list.finessCode')}</th>
                                        <th scope="col">{t('customer.list.structureType')}</th>
                                        <th scope="col">{t('customer.list.software')}</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {customers && customers.map((customer, key) => (
                                    <tr
                                        key={key}
                                    >
                                        <td>{customer.groupName}</td>
                                        <td>{customer.name}</td>
                                        <td>{customer.finessCode}</td>
                                        <td>{customer.structureType}</td>
                                        <td>{customer.software}</td>
                                        <td>
                                            {hasRight('customer_update') && <Link
                                                to={`/customer/update/${customer.id}`}
                                            >
                                                Modifier
                                            </Link>}
                                            {hasRight('customer_update') && hasRight('customer_delete') && <>&nbsp;-&nbsp;</>}
                                            {hasRight('customer_delete') && <Link
                                                to={`/customer/delete/${customer.id}`}
                                            >
                                                Supprimer
                                            </Link>}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Outlet
                    context={{setSuccessMessage}}
                />
            </div>
        </DefaultLayout>
    )
}

export default ListCustomer