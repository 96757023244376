import React, {useContext, useEffect} from "react";
import {Navigate, Outlet, useLocation, useRoutes} from "react-router-dom";
import {useAuth} from "../context/AuthContext";


const ProtectedRoute = ({children}) => {
  const { user, isLoading, refreshUser, hasRight } = useAuth()
  const {pathname} = useLocation();

  useEffect(() => {
    refreshUser()
  }, [pathname]);

  if (isLoading) {
    return <div className="flex h-screen items-center justify-center dark:bg-boxdark-2 dark:text-bodydark">
      <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid  border-t-transparent"></div>
    </div>
  }

  if (!user) {
    return <Navigate to="/login"/>;
  }

  const match = pathname.match(/[a-zA-Z]*/gm)
  const routeName = match.filter(path => path !== "").join('_') || 'home'
  if (hasRight(routeName) === false) {
    return <Navigate to="/login"/>;
  }

  return children;
};

export default ProtectedRoute;