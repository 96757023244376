import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useTiersPayant} from "../../context/TiersPayantContext";
import Stepper from "../../components/Stepper";
import Step1Document from "./steps/Step1Document";
import Step2Verification from "./steps/Step2Verification";
import Step3Validation from "./steps/Step3Validation";
import Step4AdditionalDocument from "./steps/Step4AdditionalDocument";
import Step5Cancellation from "./steps/Step5Cancellation";
import Step6ConfirmationAction from "./steps/Step6ConfirmationAction";

const initStepConfig = (tiersPayant) => {
    const initStepsConfig = [
        {
            name: "Documents",
            Component: () => <div>Provide your contact details.</div>,
            isClickable: false,
        },
    ]

    if ((tiersPayant.type !== 'prosthesis_orthodontics') || (tiersPayant.hasMedicalCareFile !== false)) {
        initStepsConfig.push({
            name: "Vérifications",
            Component: () => <div>Enter your shipping address.</div>,
            isClickable: false,
        })
    }

    const hasNull = tiersPayant.amountIsEqual === null
        || tiersPayant.invoiceNumberEqual === null
        || tiersPayant.medicalCareOnlineInvoice === null
        || tiersPayant.medicalCareSigned === null
        || tiersPayant.dateNotExpired === null

    const allValid = tiersPayant.amountIsEqual === true
        && tiersPayant.invoiceNumberEqual === true
        && tiersPayant.medicalCareOnlineInvoice === true
        && tiersPayant.medicalCareSigned === true
        && tiersPayant.dateNotExpired === true

    if (tiersPayant.type === 'prosthesis_orthodontics') {
        if (tiersPayant.hasCpamReimbursement === true) {
            initStepsConfig.push({
                name: "Décompte RO",
                Component: () => <div>Provide your contact details.</div>,
                isClickable: false,
            })
        }
    }

    if (tiersPayant.hasMedicalCareFile === false
        || (!hasNull && false === allValid)
    ) {
        initStepsConfig.push({
            name: "Annulation",
            Component: () => <div>Provide your contact details.</div>,
            isClickable: false,
        })
    } else {
        //if (tiersPayant.updateInsurance !== false) {
            if (tiersPayant.calculatedSendingType === 'mail') {
                initStepsConfig.push({
                    name: "Validations",
                    Component: () => <div>Complete payment for your order.</div>,
                    isClickable: false,
                })
            } else {
                initStepsConfig.push({
                    name: "Confirmation d'action",
                    Component: () => <div>Provide your contact details.</div>,
                    isClickable: false,
                })
            }
        //}
    }

    return initStepsConfig
}

const ManageTiersPayant = ({handleClose}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [canClose, setCanClose] = useState(false)
    const [step, setStep] = useState(0)
    const [stepsConfig, setStepsConfig] = useState([])
    const [tiersPayant, setTiersPayant] = useState(0)
    const [sendingType, setSendingType] = useState(null)
    const {tiersPayants, fetchAll} = useTiersPayant()
    let { id } = useParams();

    const {setSuccessMessage} = useOutletContext()
    const {loading, apiErrors, step1Document, step2Verification, step3Validation, step4AdditionalDocument, step5Cancellation, step6ConfirmationAction, load, resetApiErrors} = useTiersPayant()

    useEffect(() => {
        if (tiersPayants) {
            setSuccessMessage(null)
            setTiersPayant(tiersPayants.find(tiersPayant => tiersPayant.id === parseInt(id)))
        }
    }, [tiersPayants]);

    useEffect(() => {
        if (tiersPayant) {
            let maxStepClickable = 0;
            const sendT = tiersPayant.calculatedSendingType
            setSendingType(sendT)
            switch (tiersPayant.runningStatus) {
                case 'documents':
                    setStep(1)
                    break;
                case 'verification':
                    setStep(2)
                    maxStepClickable=2
                    break;
                case 'additional_documents':
                    setStep(3)
                    maxStepClickable=3
                    break;
                case 'confirmation_action':
                    if (tiersPayant.hasCpamReimbursement === true) {
                        setStep(4)
                        maxStepClickable=4
                    } else {
                        setStep(3)
                        maxStepClickable=3
                    }
                    break;
                case 'cancellation':
                    if (false === tiersPayant.hasMedicalCareFile) {
                        setStep(2)
                        maxStepClickable=2
                    } else {
                        setStep(3)
                        maxStepClickable=3
                    }
                    break;
                case 'validation':
                    if (step === 1) {
                        setStep(2)
                    } else {
                        if (tiersPayant.type === 'prosthesis_orthodontics' && (sendT === 'mail' || sendT === 'web_mail')) {
                            if (true !== tiersPayant.actionIsConfirmed) {
                                setStep(4)
                                maxStepClickable=4
                            } else {
                                setStep(5)
                                maxStepClickable=5
                            }
                        } else {
                            setStep(3)
                            maxStepClickable=3
                        }
                    }

                    if (tiersPayant.type === 'prosthesis_orthodontics' && (sendingType === 'mail' || sendingType === 'web_mail')) {
                        maxStepClickable=4
                    }
                    break;
                default:
                    setStep(0)
            }

            const initStepsConfig = initStepConfig(tiersPayant)

            const newStepsConfig = initStepsConfig.map((stepConfig, index) => {
                return {...stepConfig, isClickable: (index+1) <= maxStepClickable}
            })

            setStepsConfig(newStepsConfig)
        }
    }, [tiersPayant]);

    const onClickStep = (stepNumber) => {
        setStep(stepNumber)
    }

    useEffect(() => {
        setSuccessMessage(null)
    }, []);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('tiers-payant.manage.success_message'))
            handleClose()
        }
    }, [canClose, loading, apiErrors]);

    const onSubmitStep1 = async (inputs) => {
        load()
        await step1Document(id, inputs)
    }

    const onSubmitStep2 = async (inputs) => {
        load()
        await step2Verification(id, inputs)
    }

    const onSubmitStep3 = async (inputs) => {
        await step3Validation(id, inputs)
    }

    const onSubmitStep4 = async (inputs) => {
        load()
        await step4AdditionalDocument(id, inputs)
    }

    const onSubmitStep5 = async (inputs) => {
        load()
        await step5Cancellation(id, inputs)
    }

    const onSubmitStep6 = async (inputs) => {
        load()
        await step6ConfirmationAction(id, inputs)
    }

    return (
        <>
            <Stepper stepsConfig={stepsConfig} currentStep={step} onClickStep={onClickStep} />
            {step === 1 && <Step1Document
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep1}
                apiErrors={apiErrors}
            />}
            {(step === 2 && tiersPayant.hasMedicalCareFile !== false) && <Step2Verification
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep2}
                apiErrors={apiErrors}
            />}
            {(step === 2 && tiersPayant.runningStatus === 'cancellation' && tiersPayant.hasMedicalCareFile === false) && <Step5Cancellation
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep5}
                apiErrors={apiErrors}
            />}
            {(step === 3 && tiersPayant.type === 'care' && sendingType === 'mail') && <Step3Validation
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep3}
                apiErrors={apiErrors}
            />}
            {(step === 3 && tiersPayant.type === 'prosthesis_orthodontics' && tiersPayant.hasCpamReimbursement === true) && <Step4AdditionalDocument
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep4}
                apiErrors={apiErrors}
            />}

            {(step === 3 && tiersPayant.runningStatus === 'cancellation') && <Step5Cancellation
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep5}
                apiErrors={apiErrors}
            />}

            {(step === 3 && sendingType !== 'mail' && (tiersPayant.hasCpamReimbursement === false || tiersPayant.type === 'care')) && <Step6ConfirmationAction
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep6}
                apiErrors={apiErrors}
            />}

            {(step === 4 && sendingType !== 'mail' && (tiersPayant.hasMandatoryReimbursementFile === false || tiersPayant.mandatoryReimbursementFile !== null)) && <Step6ConfirmationAction
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep6}
                apiErrors={apiErrors}
            />}
            {(step === 4 && sendingType === 'mail' ) && <Step3Validation
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep3}
                apiErrors={apiErrors}
            />}
            {(step === 5) && <Step3Validation
                tiersPayant={tiersPayant}
                loading={loading}
                handleClose={handleClose}
                onSubmit={onSubmitStep3}
                apiErrors={apiErrors}
            />}

        </>
    )
}

export default ManageTiersPayant