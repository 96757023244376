import React, {useEffect, useState} from 'react';
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Modal} from "react-bootstrap";
import {useCustomer} from "../../context/CustomerContext";

const DeleteCustomer = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [customer, setCustomer] = useState(null)
    const [canClose, setCanClose] = useState(false)
    const {setSuccessMessage} = useOutletContext()
    let { id } = useParams();

    const {customers, remove, loading, load, apiErrors, resetApiErrors} = useCustomer()

    useEffect(() => {
        if (customers) {
            setSuccessMessage(null)
            setCustomer(customers.find(customer => customer.id === parseInt(id)))
        }
    }, [customers]);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('customer.delete.success_message'))
            handleClose()
        }

    }, [canClose, loading, apiErrors]);

    const handleDelete = () => {
        load()
        remove(id)
        setCanClose(true)
    }

    const handleClose = () => {
        resetApiErrors()
        navigate('/customer')
    }

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('customer.delete.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {customer &&
                    <>
                        {t('customer.delete.message_validate_deletion')}
                        <div className="mt-3">{customer.groupName} - <strong>{customer.name}</strong> ({customer.finessCode})</div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('customer.delete.button.cancel')}
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    {t('customer.delete.button.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteCustomer