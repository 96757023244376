import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useCustomer} from "../../context/CustomerContext";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {Button, Form, Modal, Row} from "react-bootstrap";
import Select from "react-select";
import useToken from "../../hooks/useToken";

const apiURL = process.env.REACT_APP_API_URL

const softwareOptions = [
    { label: 'Desmos', value: 'Desmos' },
    { label: 'Galaxie', value: 'Galaxie' },
    { label: 'Veasy', value: 'Veasy' }
]

const UpdateCustomer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [customer, setCustomer] = useState(null)
    const [canClose, setCanClose] = useState(false)
    const [showRibFile, setShowRibFile] = useState(true)
    const {customers, loading, apiErrors, update, load, resetApiErrors} = useCustomer()
    const {token} = useToken()
    const {setSuccessMessage} = useOutletContext()

    let { id } = useParams();

    const {
        register,
        handleSubmit,
        formState: {errors: formErrors},
        control,
        setValue,
        setError,
    } = useForm({})

    const { fields, append, remove } = useFieldArray({
        control,
        name: "contacts",
    });

    useEffect(() => {
        setSuccessMessage(null)
    }, []);

    useEffect(() => {
        if (customer) {
            setValue("groupName", customer.groupName)
            setValue("name", customer.name)
            setValue("finessCode", customer.finessCode)
            setValue("structureType", customer.structureType)
            setValue("software", customer.software)
            setValue("street", customer.street)
            setValue("zipCode", customer.zipCode)
            setValue("city", customer.city)

            if (customer.ribFile) {
                setShowRibFile(false)
            }

            if (0 === fields.length) {
                if (customer.contacts.length === 0) {
                    append('')
                } else {
                    Object.keys(customer.contacts).map(key => {
                        append(customer.contacts[key])
                    })
                }
            }
        }
    }, [customer]);

    useEffect(() => {
        if (apiErrors !== null && Object.keys(apiErrors).length > 0) {
            Object.keys(apiErrors).map((key) => {
                setError(key, apiErrors[key])
            })
        }
    }, [apiErrors]);

    useEffect(() => {
        if (customers) {
            setSuccessMessage(null)
            setCustomer(customers.find(customer => customer.id === parseInt(id)))
        }
    }, [customers]);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('customer.update.success_message'))
            handleClose()
        }

    }, [canClose, loading, apiErrors]);

    const onSubmit = async (inputs) => {
        load()
        await update(id, inputs)
        setCanClose(true)
    }

    const handleClose = () => {
        resetApiErrors()
        navigate('/customer')
    }

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('customer.update.title')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="customer.groupName">
                            <Form.Label>{t('customer.form.groupName.label')}</Form.Label>
                            <Form.Control
                                type="groupName"
                                placeholder={t('customer.form.groupName.place-holder')}
                                size="lg"
                                {...register("groupName", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.groupName}
                            />
                            {formErrors && formErrors.groupName && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`customer.form.groupName.errors.${formErrors.groupName.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="customer.name">
                            <Form.Label>{t('customer.form.name.label')}</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder={t('customer.form.name.place-holder')}
                                size="lg"
                                {...register("name", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.name}
                            />
                            {formErrors && formErrors.name && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`customer.form.name.errors.${formErrors.name.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="customer.finessCode">
                        <Form.Label>{t('customer.form.finessCode.label')}</Form.Label>
                        <Form.Control
                            type="name"
                            placeholder={t('customer.form.finessCode.place-holder')}
                            size="lg"
                            {...register("finessCode", {
                                required: 'error-empty',
                            })}
                            disabled={loading}
                            isInvalid={formErrors && formErrors.finessCode}
                        />
                        {formErrors && formErrors.finessCode && (
                            <Form.Control.Feedback type="invalid">
                                {t(`customer.form.finessCode.errors.${formErrors.finessCode.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="customer.structureType">
                            <Form.Label>{t('customer.form.structureType.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('customer.form.structureType.place-holder')}
                                size="lg"
                                {...register("structureType", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.structureType}
                            />
                            {formErrors && formErrors.structureType && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`customer.form.structureType.errors.${formErrors.structureType.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="customer.software">
                            <Form.Label>{t('customer.form.software.label')}</Form.Label>
                            <Controller
                                name="software"
                                control={control}
                                size="lg"
                                {...register("software", {
                                    required: 'error-empty',
                                })}
                                render={({field, value, name, ref}) => (
                                    <Select
                                        fieldName={field.name}
                                        placeholder={t('customer.form.software.place-holder')}
                                        inputRef={field.ref}
                                        options={softwareOptions}
                                        value={softwareOptions.find((c) => c.value === field.value)}
                                        onChange={val => field.onChange(val?.value)}
                                        disabled={loading}
                                        isInvalid={formErrors && formErrors.software}
                                        className={`p-0 form-control form-control-lg ${formErrors && formErrors.software ? ' is-invalid' : ''}`}
                                    />)
                                }
                            />
                            {formErrors && formErrors.software && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`customer.form.software.errors.${formErrors.software.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="customer.street">
                        <Form.Label>{t('customer.form.street.label')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('customer.form.street.place-holder')}
                            size="lg"
                            {...register("street", {
                                required: 'error-empty',
                            })}
                            disabled={loading}
                            isInvalid={formErrors && formErrors.street}
                        />
                        {formErrors && formErrors.street && (
                            <Form.Control.Feedback type="invalid">
                                {t(`customer.form.street.errors.${formErrors.street.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="customer.zipCode">
                            <Form.Label>{t('customer.form.zipCode.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('customer.form.zipCode.place-holder')}
                                size="lg"
                                {...register("zipCode", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.zipCode}
                            />
                            {formErrors && formErrors.zipCode && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`customer.form.zipCode.errors.${formErrors.zipCode.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="customer.city">
                            <Form.Label>{t('customer.form.city.label')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('customer.form.city.place-holder')}
                                size="lg"
                                {...register("city", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.city}
                            />
                            {formErrors && formErrors.city && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`customer.form.city.errors.${formErrors.city.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="customer.rib-file">
                            <Form.Label>
                                {t('customer.form.rib-file.label')}
                            </Form.Label>

                            {customer && customer.ribFile && (
                                <div>
                                    Fichier téléchargé: <a target="_blank" href={`${apiURL}/api/customer/file/${customer.ribFile}?token=${token}`}>{customer.ribFile}</a>
                                    {!showRibFile && (<div><a style={{color: '#4287f5'}} onClick={() => setShowRibFile(!showRibFile)}>Le modifier ?</a></div>)}
                                </div>
                            )}

                            {showRibFile && <><Form.Control
                                type="file"
                                placeholder={t('customer.form.rib-file.place-holder')}
                                size="lg"
                                {...register("ribFile", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.ribFile}
                            />
                            {formErrors && formErrors.ribFile && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`customer.form.rib-file.errors.${formErrors.ribFile.message}`)}
                                </Form.Control.Feedback>
                            )}</>}
                        </Form.Group>
                    </Row>
                    <Form.Label>{t('customer.form.contacts.label')}</Form.Label>

                    {fields.map((item, index) => {
                        return (
                            <Form.Group key={item.id} className="mb-3 input-group" controlId="customer.contact.email">
                                <Form.Control
                                    type="text"
                                    placeholder={t('customer.form.contacts.role.place-holder')}
                                    size="lg"
                                    {...register(`contacts.${index}.role`)}
                                    disabled={loading}
                                    isInvalid={formErrors && formErrors.contacts && formErrors.contacts[index].role}
                                />
                                <Form.Control
                                    type="text"
                                    placeholder={t('customer.form.contacts.name.place-holder')}
                                    size="lg"
                                    {...register(`contacts.${index}.name`)}
                                    disabled={loading}
                                    isInvalid={formErrors && formErrors.contacts && formErrors.contacts[index].name}
                                />
                                <Form.Control
                                    type="text"
                                    placeholder={t('customer.form.contacts.email.place-holder')}
                                    size="lg"
                                    {...register(`contacts.${index}.email`, {
                                        pattern: {
                                            value: /^[a-zA-Z0-9./+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: 'error-not-email',
                                        }
                                    })}
                                    disabled={loading}
                                    isInvalid={formErrors && formErrors.contacts && formErrors.contacts[index].email}
                                />
                                <Form.Control
                                    type="text"
                                    placeholder={t('customer.form.contacts.phone.place-holder')}
                                    size="lg"
                                    {...register(`contacts.${index}.phone`)}
                                    disabled={loading}
                                    isInvalid={formErrors && formErrors.contacts && formErrors.contacts[index].phone}
                                />
                                <div className="input-group-append">
                                    <Button
                                        variant="danger"
                                        size="lg"
                                        type="button"
                                        onClick={() => remove(index)}
                                    >
                                        x
                                    </Button>
                                </div>
                                {formErrors && formErrors.contacts && formErrors.contacts[index].email && (
                                    <Form.Control.Feedback type="invalid">
                                        {t(`customer.form.contacts.errors.${formErrors.contacts[index].email.message}`)}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        );
                    })}

                    <div className="mb-3 text-center">
                        <Button
                            type="button"
                            onClick={() => {
                                append("");
                            }}>
                            {t('customer.update.button.add_contact')}
                        </Button>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        {t('customer.update.button.cancel')}
                    </Button>
                    <Button variant="primary" type="submit">
                        {t('customer.update.button.save')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default UpdateCustomer