import React, {useEffect, useState} from 'react';
import {Modal, Button, Form, Row} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useFieldArray, useForm} from "react-hook-form";
import {useHealthInsurance} from "../../context/HealthInsuranceContext";

const CreateHealthInsurance = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [canClose, setCanClose] = useState(false)
    const [formErrors, setFormErrors] = useState(false)
    const {loading, apiErrors, add, load, resetApiErrors} = useHealthInsurance()

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({})

    const { fields, append, remove } = useFieldArray({
        control,
        name: "emails",
    });
    const {setSuccessMessage} = useOutletContext()

    useEffect(() => {
        setSuccessMessage(null)
        if (0 === fields.length) {
            append('')
        }
    }, []);

    useEffect(() => {
        setFormErrors({...errors, ...apiErrors})
    }, [errors])

    useEffect(() => {
        setFormErrors({...apiErrors, ...errors})
    }, [apiErrors]);

    useEffect(() => {
        if (canClose && !loading && !apiErrors) {
            setSuccessMessage(t('health-insurance.add-health-insurance.success_message'))
            handleClose()
        }

    }, [canClose, loading, apiErrors]);

    const onSubmit = async (inputs) => {
        load()
        await add(inputs)
        setCanClose(true)
    }

    const handleClose = () => {
        resetApiErrors()
        navigate('/health-insurance')
    }

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('health-insurance.add-health-insurance.title')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="health-insurance.name">
                            <Form.Label>{t('health-insurance.form.name.label')}</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder={t('health-insurance.form.name.place-holder')}
                                size="lg"
                                {...register("name", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.name}
                            />
                            {formErrors && formErrors.name && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`health-insurance.form.name.errors.${formErrors.name.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="health-insurance.amcCode">
                            <Form.Label>{t('health-insurance.form.amcCode.label')}</Form.Label>
                            <Form.Control
                                type="amcCode"
                                placeholder={t('health-insurance.form.amcCode.place-holder')}
                                size="lg"
                                {...register("amcCode", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.amcCode}
                            />
                            {formErrors && formErrors.amcCode && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`health-insurance.form.amcCode.errors.${formErrors.amcCode.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3 col-md-6" controlId="health-insurance.sendingTypeForCare">
                            <Form.Label>{t('health-insurance.form.sendingTypeForCare.label')}</Form.Label>
                            <Form.Select
                                type="sendingTypeForCare"
                                size="lg"
                                {...register("sendingTypeForCare", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.sendingTypeForCare}
                            >
                                <option value="">{t('health-insurance.form.sendingTypeForCare.place-holder')}</option>
                                <option value="teletransmission">{t('health-insurance.sendingType.teletransmission')}</option>
                                <option value="web_portal">{t('health-insurance.sendingType.web_portal')}</option>
                                <option value="letter">{t('health-insurance.sendingType.letter')}</option>
                                <option value="mail">{t('health-insurance.sendingType.mail')}</option>
                            </Form.Select>
                            {formErrors && formErrors.sendingTypeForCare && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`health-insurance.form.sendingTypeForCare.errors.${formErrors.sendingTypeForCare.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3 col-md-6" controlId="health-insurance.sendingTypeForProsthesisOrthodontics">
                            <Form.Label>{t('health-insurance.form.sendingTypeForProsthesisOrthodontics.label')}</Form.Label>
                            <Form.Select
                                type="sendingTypeForProsthesisOrthodontics"
                                size="lg"
                                {...register("sendingTypeForProsthesisOrthodontics", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.sendingTypeForProsthesisOrthodontics}
                            >
                                <option value="">{t('health-insurance.form.sendingTypeForProsthesisOrthodontics.place-holder')}</option>
                                <option value="teletransmission">{t('health-insurance.sendingType.teletransmission')}</option>
                                <option value="web_portal">{t('health-insurance.sendingType.web_portal')}</option>
                                <option value="letter">{t('health-insurance.sendingType.letter')}</option>
                                <option value="mail">{t('health-insurance.sendingType.mail')}</option>
                                <option value="web_mail">{t('health-insurance.sendingType.web_mail')}</option>
                            </Form.Select>
                            {formErrors && formErrors.sendingTypeForProsthesisOrthodontics && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`health-insurance.form.sendingTypeForProsthesisOrthodontics.errors.${formErrors.sendingTypeForProsthesisOrthodontics.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="health-insurance.phoneNumber">
                        <Form.Label>{t('health-insurance.form.phoneNumber.label')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('health-insurance.form.phoneNumber.place-holder')}
                            size="lg"
                            {...register("phoneNumber")}
                            disabled={loading}
                            isInvalid={formErrors && formErrors.phoneNumber}
                        />
                        {formErrors && formErrors.phoneNumber && (
                            <Form.Control.Feedback type="invalid">
                                {t(`health-insurance.form.phoneNumber.errors.${formErrors.phoneNumber.message}`)}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Label>{t('health-insurance.form.emails.label')}</Form.Label>

                        {fields.map((item, index) => {
                            return (
                                <Form.Group key={item.id} className="mb-3 input-group" controlId="health-insurance.phoneNumber">

                                    <Form.Control
                                        type="text"
                                        placeholder={t('health-insurance.form.emails.place-holder')}
                                        size="lg"
                                        {...register(`emails.${index}`, {
                                            pattern: {
                                                value: /^[a-zA-Z0-9./+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                message: 'error-not-email',
                                            }
                                        })}
                                        disabled={loading}
                                        isInvalid={formErrors && formErrors.emails && formErrors.emails[index]}
                                    />
                                    <div className="input-group-append">
                                        <Button
                                            variant="danger"
                                            size="lg"
                                            type="button"
                                            onClick={() => remove(index)}
                                        >
                                            x
                                        </Button>
                                    </div>
                                    {formErrors && formErrors.emails && formErrors.emails[index] && (
                                        <Form.Control.Feedback type="invalid">
                                            {t(`health-insurance.form.emails.errors.${formErrors.emails[index].message}`)}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            );
                        })}

                    <div className="mb-3 text-center">
                        <Button
                            type="button"
                            onClick={() => {
                                append("");
                            }}>
                            {t('health-insurance.add-health-insurance.button.add_email')}
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        {t('health-insurance.add-health-insurance.button.cancel')}
                    </Button>
                    <Button variant="primary" type="submit">
                        {t('health-insurance.add-health-insurance.button.save')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default CreateHealthInsurance