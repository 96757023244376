import React, {useEffect, useState} from "react";
import {useHealthInsurances} from "../hooks/api/healthInsurances";
import Select from "react-select";
import {Controller} from "react-hook-form";

const SelectHealthInsurance = ({fieldName, healthInsurances, fetchAllHealthInsurance, placeholder, inputRef, onChange, isInvalid, className, ...props}) => {
    const [healthInsurancesOptions, setHealthInsurancesOptions] = useState([])

    useEffect(() => {
        if (healthInsurances) {
            const options = healthInsurances.map((healthInsurance => {
                return { label: healthInsurance.name, value: healthInsurance.id }
            }))
            setHealthInsurancesOptions(options)
        }

    }, [healthInsurances])

    useEffect(() => {
        fetchAllHealthInsurance()
    }, []);

    return (
        <Controller
            {...props}
            render={({ field, value, name, ref }) => (
                <Select
                    fieldName={field.name}
                    placeholder={placeholder}
                    inputRef={field.ref}
                    options={healthInsurancesOptions}
                    value={healthInsurancesOptions.find((c) => c.value === field.value)}
                    onChange={val => field.onChange(val?.value)}
                    isInvalid={isInvalid}
                    className={className}
                    isClearable
                />)
            }
        />
    )
}

export default SelectHealthInsurance