import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import {Link, Outlet} from "react-router-dom";
import SuccessAlert from "../../components/Layout/SuccessAlert";
import {useHealthInsurance} from "../../context/HealthInsuranceContext";
import {useAuth} from "../../context/AuthContext";

const ListHealthInsurance = () => {
    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState(null)
    const {healthInsurances, fetchAll} = useHealthInsurance()
    const {hasRight} = useAuth()

    useEffect(() => {
        fetchAll()
    }, []);

    return (
        <DefaultLayout>
            {hasRight('health_insurance_new') && <Link
                to={'/health-insurance/new'}
                className="btn btn-primary float-end mt-n1"
            >
                <i className="fas fa-plus" style={{marginRight: '10px'}}></i>
                {t('health-insurance.list.button.add-health-insurance')}
            </Link>}
            <h1 className="h3 mb-3">{t('health-insurance.list.title')}</h1>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        {successMessage && <SuccessAlert message={successMessage} /> }
                        <div className="table-responsive">
                            <table className="table mb-0  table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">{t('health-insurance.list.name')}</th>
                                    <th scope="col">{t('health-insurance.list.amcCode')}</th>
                                    <th scope="col">{t('health-insurance.list.sendingTypeForCare')}</th>
                                    <th scope="col">{t('health-insurance.list.sendingTypeForProsthesisOrthodontics')}</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {healthInsurances && healthInsurances.map((healthInsurance, key) => (
                                    <tr
                                        key={healthInsurance.id}
                                    >
                                        <td>{healthInsurance.name}</td>
                                        <td>{healthInsurance.amcCode}</td>
                                        <td>{t(`health-insurance.sendingType.${healthInsurance.sendingTypeForCare}`)}</td>
                                        <td>{t(`health-insurance.sendingType.${healthInsurance.sendingTypeForProsthesisOrthodontics}`)}</td>
                                        <td>
                                            {hasRight('health_insurance_update') && <Link
                                                to={`/health-insurance/update/${healthInsurance.id}`}
                                            >
                                                Modifier
                                            </Link>}
                                            {hasRight('health_insurance_update') && hasRight('health_insurance_delete') && <>&nbsp;-&nbsp;</>}
                                            {hasRight('health_insurance_delete') && <Link
                                                to={`/health-insurance/delete/${healthInsurance.id}`}
                                            >
                                                Supprimer
                                            </Link>}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Outlet
                    context={{setSuccessMessage}}
                />
            </div>
        </DefaultLayout>
    )
}

export default ListHealthInsurance