import {createContext, useContext} from "react";
import {useHealthInsurances} from "../hooks/api/healthInsurances";

const HealthInsuranceContext = createContext();

const HealthInsuranceProvider = ({children}) => {
    const [{healthInsurances, apiErrors, loading}, {fetchAll, add, update, remove, resetApiErrors, load}] = useHealthInsurances()

    return <HealthInsuranceContext.Provider value={{ healthInsurances, apiErrors, loading, fetchAll, add, update, remove, resetApiErrors, load }}>
        {children}
    </HealthInsuranceContext.Provider>;
}

export default HealthInsuranceProvider

export const useHealthInsurance = () => {
    return useContext(HealthInsuranceContext);
};