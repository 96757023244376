import React, {useEffect, useState} from 'react';
import DefaultLayout from "../components/Layout/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Link, Outlet} from "react-router-dom";
import {useUsers} from "../context/UsersContext";
import SuccessAlert from "../components/Layout/SuccessAlert";
import {useAuth} from "../context/AuthContext";

const Users = () => {
    const { t } = useTranslation();
    const {users, fetchUsers} = useUsers()
    const [successMessage, setSuccessMessage] = useState(null)
    const {hasRight} = useAuth()

    useEffect(() => {
        fetchUsers()
    }, []);

    return (
        <DefaultLayout>
            {hasRight('user_new') && <Link
                to={'/user/new'}
                className="btn btn-primary float-end mt-n1"
            >
                <i className="fas fa-plus" style={{marginRight: '10px'}}></i>
                {t('users.list.button.add_user')}
            </Link>}
            <h1 className="h3 mb-3">{t('users.list.title')}</h1>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        {successMessage && <SuccessAlert message={successMessage} /> }
                        <div className="table-responsive">
                            <table className="table mb-0  table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">{t('users.list.name')}</th>
                                    <th scope="col">{t('users.list.role')}</th>
                                    <th scope="col">{t('users.list.active')}</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {users && users.map((user, key) => (
                                    <tr
                                        key={user.email}
                                    >
                                        <td>{user.firstName} {user.lastName}</td>
                                        <td>{user.role && t(`users.role.${user.role}`)}</td>
                                        <td>{t(`users.isActive.${user.isActive ? 'true' : 'false'}`)}</td>
                                        <td>
                                            {hasRight('user_update') && <Link
                                                to={`/user/update/${user.id}`}
                                            >
                                                Modifier
                                            </Link>}
                                            {hasRight('user_update') && hasRight('user_delete') && <>&nbsp;-&nbsp;</>}
                                            {hasRight('user_delete') && <Link
                                                to={`/user/delete/${user.id}`}
                                            >
                                                Supprimer
                                            </Link>}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Outlet context={{setSuccessMessage}} />
            </div>
        </DefaultLayout>
    )
}

export default Users