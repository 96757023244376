import {createContext, useContext} from "react";
import {useCustomers} from "../hooks/api/customer";

const CustomerContext = createContext();

const CustomerProvider = ({children}) => {
    const [{customers, apiErrors, loading}, {fetchAll, add, update, remove, resetApiErrors, load}] = useCustomers()

    return <CustomerContext.Provider value={{ customers, apiErrors, loading, fetchAll, add, update, remove, resetApiErrors, load }}>
        {children}
    </CustomerContext.Provider>;
}

export default CustomerProvider

export const useCustomer = () => {
    return useContext(CustomerContext);
};