import React from "react";
import {useTranslation} from "react-i18next";


const TiersPayantStatusBadge = ({status, ...props}) => {
    const { t } = useTranslation();
    let color = ''
    if (status === 'new') {
        color = '#19E7CA'
    } else if (status === 'waiting_mandatory_reimbursement_file') {
        color = '#3367D9'
    } else if (status === 'waiting_sending') {
        color = '#32419C'
    } else if (status === 'waiting_invoice') {
        color = '#1F9BCF'
    } else if (status === 'sent') {
        color = '#0437FF'
    } else if (status === 'closed') {
        color = '#1E116F'
    } else if (status === 'impossible_invoicing_internal') {
        color = '#FF8B13'
    } else if (status === 'impossible_invoicing_waiting_customer') {
        color = '#BA6712'
    } else if (status === 'canceled_internal') {
        color = '#E50000'
    } else if (status === 'canceled_waiting_customer') {
        color = '#B02000'
    } else if (status === 'waiting_paiement') {
        color = '#00C3EC'
    } else if (status === 'restart_refund') {
        color = '#FFB800'
    } else if (status === 'restart_refund_impossible_internal') {
        color = '#495057'
    } else if (status === 'restart_refund_impossible_ask_document') {
        color = '#495057'
    } else if (status === 'restart_refund_impossible_ask_invoice') {
        color = '#495057'
    } else if (status === 'refund_restarted') {
        color = '#7505FF'
    } else if (status === 'paid') {
        color = '#00752F'
    }

    return (
        <span className={`badge`} style={{backgroundColor: `${color}`}} {...props}>
            {t(`tiers-payant.status.${status}`)}
        </span>
    )
}

export default TiersPayantStatusBadge