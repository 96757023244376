import {useTranslation} from "react-i18next";
import useToken from "../../hooks/useToken";
import {useForm} from "react-hook-form";
import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTiersPayant} from "../../context/TiersPayantContext";

const apiURL = process.env.REACT_APP_API_URL

const FilesTiersPayant = ({tiersPayant, handleClose}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showInvoiceFile, setShowInvoiceFile] = useState(true)
    const [showInsuranceCardFile, setShowInsuranceCardFile] = useState(true)
    const [showMedicalCareFile, setShowMedicalCareFile] = useState(true)
    const [showMandatoryReimbursementFile, setShowMandatoryReimbursementFile] = useState(true)
    const {loading, apiErrors, document, deleteDocument, resetApiErrors} = useTiersPayant()
    let { id } = useParams();

    const {token} = useToken()
    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
    } = useForm({})
    const onSubmit = (inputs) => {
        document(id, inputs)
    }

    const handleRemoveFile = (id) => {
        deleteDocument(tiersPayant.id, id)
    }

    useEffect(() => {
        if (tiersPayant && tiersPayant.invoiceFile) {
            setShowInvoiceFile(false)
        }
        if (tiersPayant && tiersPayant.insuranceCardFile) {
            setShowInsuranceCardFile(false)
        }
        if (tiersPayant && tiersPayant.medicalCareFile) {
            setShowMedicalCareFile(false)
        }
        if (tiersPayant && tiersPayant.mandatoryReimbursementFile) {
            setShowMandatoryReimbursementFile(false)
        }
    }, [tiersPayant]);

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ul style={{listStyleType: 'none'}}>
                    <li>
                        <Form.Group className="mb-3" controlId="tiers-payant.invoice-file">
                            <Form.Label>
                                <strong>{t('tiers-payant.manage.form.invoice-file.label')}: </strong>
                                {tiersPayant.invoiceFile && <a target="_blank"
                                                               href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${tiersPayant.invoiceFile}?token=${token}`}>{tiersPayant.invoiceFile}</a>}
                            </Form.Label>

                            {!showInvoiceFile && (
                                <div><a style={{color: '#4287f5'}} onClick={() => setShowInvoiceFile(!showInvoiceFile)}>Le
                                    modifier ?</a></div>)}
                            {showInvoiceFile && (
                                <>
                                    <Form.Control
                                        type="file"
                                        size="lg"
                                        {...register("invoiceFile")}
                                        //disabled={loading}
                                        isInvalid={formErrors && formErrors.invoiceFile}
                                    />
                                    {formErrors && formErrors.invoiceFile && (
                                        <Form.Control.Feedback type="invalid">
                                            {t(`tiers-payant.manage.form.invoice-file.errors.${formErrors.invoiceFile.message}`)}
                                        </Form.Control.Feedback>
                                    )}
                                </>)}
                        </Form.Group>
                    </li>

                    <li>
                        <Form.Group className="mb-3" controlId="tiers-payant.insurance-card-file">
                            <Form.Label>
                                <strong>{t('tiers-payant.manage.form.insurance-card-file.label')}: </strong>
                                {tiersPayant.insuranceCardFile && <a target="_blank"
                                                                     href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${tiersPayant.insuranceCardFile}?token=${token}`}>{tiersPayant.insuranceCardFile}</a>}
                            </Form.Label>

                            {!showInsuranceCardFile && (
                                <div><a style={{color: '#4287f5'}}
                                        onClick={() => setShowInsuranceCardFile(!showInsuranceCardFile)}>Le
                                    modifier ?</a></div>)}
                            {showInsuranceCardFile && (
                                <>
                                    <Form.Control
                                        type="file"
                                        size="lg"
                                        {...register("insuranceCardFile")}
                                        //disabled={loading}
                                        isInvalid={formErrors && formErrors.insuranceCardFile}
                                    />
                                    {formErrors && formErrors.insuranceCardFile && (
                                        <Form.Control.Feedback type="invalid">
                                            {t(`tiers-payant.manage.form.insurance-card-file.errors.${formErrors.insuranceCardFile.message}`)}
                                        </Form.Control.Feedback>
                                    )}
                                </>)}
                        </Form.Group>
                    </li>

                    <li>
                        <Form.Group className="mb-3" controlId="tiers-payant.mandatory-reimbursement-file">
                            <Form.Label>
                                <strong>{t('tiers-payant.manage.form.mandatory-reimbursement-file.label')}: </strong>
                                {tiersPayant.mandatoryReimbursementFile && <a target="_blank"
                                                                              href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${tiersPayant.mandatoryReimbursementFile}?token=${token}`}>{tiersPayant.mandatoryReimbursementFile}</a>}
                            </Form.Label>

                            {!showMandatoryReimbursementFile && (
                                <div><a style={{color: '#4287f5'}}
                                        onClick={() => setShowMandatoryReimbursementFile(!showMandatoryReimbursementFile)}>Le
                                    modifier ?</a></div>)}
                            {showMandatoryReimbursementFile && (
                                <>
                                    <Form.Control
                                        type="file"
                                        size="lg"
                                        {...register("mandatoryReimbursementFile")}
                                        //disabled={loading}
                                        isInvalid={formErrors && formErrors.mandatoryReimbursementFile}
                                    />
                                    {formErrors && formErrors.mandatoryReimbursementFile && (
                                        <Form.Control.Feedback type="invalid">
                                            {t(`tiers-payant.manage.form.mandatory-reimbursement-file.errors.${formErrors.mandatoryReimbursementFile.message}`)}
                                        </Form.Control.Feedback>
                                    )}
                                </>)}
                        </Form.Group>
                    </li>

                    <li>
                        <Form.Group className="mb-3" controlId="tiers-payant.medical-care-file">
                            <Form.Label>
                                <strong>{t('tiers-payant.manage.form.medical-care-file.label')}: </strong>
                                {tiersPayant.medicalCareFile && <a target="_blank"
                                                                   href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${tiersPayant.medicalCareFile}?token=${token}`}>{tiersPayant.medicalCareFile}</a>}
                            </Form.Label>

                            {!showMedicalCareFile && (
                                <div><a style={{color: '#4287f5'}}
                                        onClick={() => setShowMedicalCareFile(!showMedicalCareFile)}>Le
                                    modifier ?</a></div>)}
                            {showMedicalCareFile && (
                                <>
                                    <Form.Control
                                        type="file"
                                        size="lg"
                                        {...register("medicalCareFile")}
                                        //disabled={loading}
                                        isInvalid={formErrors && formErrors.medicalCareFile}
                                    />
                                    {formErrors && formErrors.medicalCareFile && (
                                        <Form.Control.Feedback type="invalid">
                                            {t(`tiers-payant.manage.form.medical-care-file.errors.${formErrors.medicalCareFile.message}`)}
                                        </Form.Control.Feedback>
                                    )}
                                </>)}
                        </Form.Group>
                    </li>

                    <li>
                        <Form.Group className="mb-3" controlId="tiers-payant.invoice-file">
                            <div style={{marginBottom: '.5rem'}}>
                                <strong>{t('tiers-payant.manage.form.other-files.label')}: </strong>
                                {tiersPayant && Object.keys(tiersPayant.otherFiles).length > 0 && <ul>
                                    {tiersPayant.otherFiles.map(otherFile => {
                                        return <li>
                                            <a
                                                target="_blank"
                                                href={`${apiURL}/api/tiers-payants/${tiersPayant.id}/file/${otherFile.fileName}?token=${token}`}
                                            >
                                                {otherFile.fileName}
                                            </a>
                                            <i style={{cursor: 'pointer', marginLeft: '10px'}} className="fa-solid fa-trash-can" onClick={() => handleRemoveFile(otherFile.id)}></i>
                                        </li>
                                    })}
                                </ul>}
                            </div>
                            <>
                                <Form.Control
                                    type="file"
                                    size="lg"
                                    {...register("otherFile")}
                                    //disabled={loading}
                                    isInvalid={formErrors && formErrors.otherFile}
                                />
                                {formErrors && formErrors.otherFile && (
                                    <Form.Control.Feedback type="invalid">
                                        {t(`tiers-payant.manage.form.other-file.errors.${formErrors.otherFile.message}`)}
                                    </Form.Control.Feedback>
                                )}
                            </>
                        </Form.Group>
                    </li>


                </ul>
            </Form>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                    {t('tiers-payant.manage.button.next')}
                </Button>
            </Modal.Footer>
        </div>
    )
}

export default FilesTiersPayant