import {useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useTiersPayant} from "../../context/TiersPayantContext";
import {useForm} from "react-hook-form";
import {Button, Form, Modal, Row, Spinner} from "react-bootstrap";
import SuccessAlert from "../../components/Layout/SuccessAlert";

const ImportTiersPayant = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {setSuccessMessage} = useOutletContext()
    const {loading, importStats, apiErrors, importFile, load, resetApiErrors} = useTiersPayant()

    const {
        register,
        handleSubmit,
        formState: {errors: formErrors},
        setError,
    } = useForm({})

    useEffect(() => {
        setSuccessMessage(null)
        resetApiErrors()
    }, []);

    useEffect(() => {
        if (apiErrors !== null && Object.keys(apiErrors).length > 0) {
            Object.keys(apiErrors).map((key) => {
                setError(key, apiErrors[key])
            })
        }
    }, [apiErrors]);

    const onSubmit = async (inputs) => {
        load()
        await importFile(inputs.file[0])
    }

    const handleClose = () => {
        if (!loading) {
            resetApiErrors()
            navigate('/tiers-payant')
        }
    }

    return (
        <Modal
            show={true}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('tiers-payant.import.title')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    {!importStats && (
                        <Form.Group className="mb-3 col-md-6" controlId="tiers-payant.file">
                            <Form.Label>{t('tiers-payant.form.file.label')}</Form.Label>
                            <Form.Control
                                type="file"
                                placeholder={t('tiers-payant.form.file.place-holder')}
                                size="lg"
                                {...register("file", {
                                    required: 'error-empty',
                                })}
                                disabled={loading}
                                isInvalid={formErrors && formErrors.file}
                            />
                            {formErrors && formErrors.file && (
                                <Form.Control.Feedback type="invalid">
                                    {t(`tiers-payant.form.file.errors.${formErrors.file.message}`)}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    )}
                    {importStats && (<SuccessAlert message={(
                        <div>
                            <p>L'import du fichier c'est bien déroulé.</p>
                            <ul>
                                <li>Il y avait un total de {importStats.nbLine} lignes.</li>
                                <li>Il y a eu {importStats.nbImported} importés.</li>
                                {importStats.nbInvoiceNumberAlreadyExist > 0 && (
                                    importStats.nbInvoiceNumberAlreadyExist === 1 ?
                                        <li>Le fichier contenait un N° de facture dont les lignes n'ont pas été importé.</li>
                                        : <li>Le fichier contenait {importStats.nbHealthInsuranceNotExist} N° de facture dont les lignes n'ont pas été importé.</li>
                                )}
                                {importStats.nbHealthInsuranceNotExist > 0 && (
                                    importStats.nbHealthInsuranceNotExist === 1 ?
                                        <li>Le fichier contenait une mutuelle dont les lignes n'ont pas été importé.</li>
                                        : <li>Le fichier contenait {importStats.nbHealthInsuranceNotExist} mutuelles dont les lignes n'ont pas été importé.</li>
                                )}
                                {importStats.nbCustomerNotExist > 0 && (
                                    importStats.nbCustomerNotExist === 1 ?
                                        <li>Le fichier contenait un centre de santé dont les lignes n'ont pas été importé.</li>
                                        : <li>Le fichier contenait {importStats.nbCustomerNotExist} centres de santé dont les lignes n'ont pas été importé.</li>
                                )}
                            </ul>
                        </div>
                    )} />)}
                </Modal.Body>
                <Modal.Footer>
                    {!importStats && (
                        <>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                disabled={loading}
                            >
                                {t('tiers-payant.import.button.cancel')}
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                            >
                                {loading && <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    style={{marginRight: '10px'}}
                                />}
                                {t('tiers-payant.import.button.save')}
                            </Button>
                        </>
                    )}
                    {importStats && (
                        <Button
                            variant="primary"
                            onClick={handleClose}
                            disabled={loading}
                        >
                            {t('tiers-payant.import.button.close')}
                        </Button>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ImportTiersPayant