import {Button, Form, Modal, Alert} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import CreatableSelect from 'react-select/creatable';

const Step3Validation = ({tiersPayant, loading, handleClose, onSubmit, apiErrors}) => {
    const { t } = useTranslation();
    const [emails, setEmails] = useState([])
    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
        setError,
    } = useForm({})

    const isValid = tiersPayant.insuranceIsCorrect !== false || tiersPayant.updateInsurance !== false
    const sendingType = tiersPayant.calculatedSendingType

    useEffect(() => {
        if (tiersPayant) {
            const options = tiersPayant.healthInsurance.emails.map((email => {
                return { label: email.email, value: email.id }
            }))
            setEmails(options)
        }
    }, [tiersPayant]);

    useEffect(() => {
        if (apiErrors !== null && Object.keys(apiErrors).length > 0) {
            Object.keys(apiErrors).map((key) => {
                setError(key, apiErrors[key])
            })
        }
    }, [apiErrors]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="tiers-payant.send-to">
                <Form.Label>{t('tiers-payant.manage.form.send-to.label')}</Form.Label>
                <Controller
                    name="sendTo"
                    control={control}
                    size="lg"
                    {...register("sendTo", {
                        required: 'error-empty',
                    })}
                    render={({ field, value, name, ref }) => (
                        <CreatableSelect
                            fieldName={field.name}
                            placeholder={t('tiers-payant.manage.form.send-to.placeholder')}
                            inputRef={field.ref}
                            options={emails}
                            value={emails.find((c) => c.value === field.value)}
                            onChange={val => field.onChange(val.value)}
                            isInvalid={formErrors && formErrors.sendTo}
                            className={`p-0 form-control form-control-lg ${formErrors && formErrors.sendTo ? ' is-invalid' : ''}`}
                        />)
                    }
                />
                {formErrors && formErrors.sendTo && (
                    <Form.Control.Feedback type="invalid">
                        {t(`tiers-payant.manage.form.send-to.errors.${formErrors.sendTo.message}`)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            {(tiersPayant.mailType >= 3 && tiersPayant.mailType !== 6 ) && <Form.Group className="mb-3" controlId="tiers-payant.mail-information">
                <Form.Label>{t('tiers-payant.manage.form.mail-information.label')}</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    type="textarea"
                    size="lg"
                    {...register("mailInformation", {
                        required: 'error-empty',
                    })}
                    isInvalid={formErrors && formErrors.mailInformation}
                />
                {formErrors && formErrors.mailInformation && (
                    <Form.Control.Feedback type="invalid">
                        {t(`tiers-payant.manage.form.mail-information.errors.${formErrors.mailInformation.message}`)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>}

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    {t('tiers-payant.manage.button.cancel')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('tiers-payant.manage.button.save')}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

export default Step3Validation